
import VueHtml5Editor from 'vue-html5-editor'
import { indexApi } from '@/apis/index'

export default {
    install: (vue) => {
        vue.use(VueHtml5Editor, {
            // 全局组件名称，使用new VueHtml5Editor(options)时该选项无效 
            // global component name
            name: "vue-html5-editor",
            // 是否显示模块名称，开启的话会在工具栏的图标后台直接显示名称
            // if set true,will append module name to toolbar after icon
            showModuleName: false,
            // 自定义各个图标的class，默认使用的是font-awesome提供的图标
            // custom icon class of built-in modules,default using font-awesome
            icons: {
                text: "fa fa-pencil",
                color: "fa fa-paint-brush",
                font: "fa fa-font",
                align: "fa fa-align-justify",
                list: "fa fa-list",
                link: "fa fa-chain",
                unlink: "fa fa-chain-broken",
                tabulation: "fa fa-table",
                image: "fa fa-file-image-o",
                hr: "fa fa-minus",
                eraser: "fa fa-eraser",
                undo: "fa-undo fa",
                "full-screen": "fa fa-arrows-alt",
                info: "fa fa-info"
            },
            // 语言，内建的有英文（en-us）和中文（zh-cn）
            //default en-us, en-us and zh-cn are built-in
            language: "zh-cn",
            // 自定义语言
            i18n: {
                //specify your language here
                "zh-cn": {
                    "align": "对齐方式",
                    "image": "图片",
                    "list": "列表",
                    "link": "链接",
                    "unlink": "去除链接",
                    "table": "表格",
                    "font": "文字",
                    "full screen": "全屏",
                    "text": "排版",
                    "eraser": "格式清除",
                    "info": "关于",
                    "color": "颜色",
                    "please enter a url": "请输入地址",
                    "create link": "创建链接",
                    "bold": "加粗",
                    "italic": "倾斜",
                    "underline": "下划线",
                    "strike through": "删除线",
                    "subscript": "上标",
                    "superscript": "下标",
                    "heading": "标题",
                    "font name": "字体",
                    "font size": "文字大小",
                    "left justify": "左对齐",
                    "center justify": "居中",
                    "right justify": "右对齐",
                    "ordered list": "有序列表",
                    "unordered list": "无序列表",
                    "fore color": "前景色",
                    "background color": "背景色",
                    "row count": "行数",
                    "column count": "列数",
                    "save": "确定",
                    "upload": "上传",
                    "progress": "进度",
                    "unknown": "未知",
                    "please wait": "请稍等",
                    "error": "错误",
                    "abort": "中断",
                    "reset": "重置"
                }
            },
            // 隐藏不想要显示出来的模块
            // the modules you don't want
            hiddenModules: [],
            // 自定义要显示的模块，并控制顺序
            // keep only the modules you want and customize the order.
            // can be used with hiddenModules together
            visibleModules: [
                "text",
                "color",
                "font",
                "align",
                "list",
                // "link",
                // "unlink",
                // "tabulation",
                // "image",
                "hr",
                "eraser",
                "undo",
                // "full-screen",
                "info",
                "img"
            ],
            // 扩展模块，具体可以参考examples或查看源码
            // extended modules
            modules: [
                //omit,reference to source code of build-in modules
                {
                    name: "img",
                    icon: "fa fa-file-image-o",
                    show: true,
                    init: function (editor) {
                        // alert("time module init, config is \r\n" + JSON.stringify(this.config))
                    },
                    handler: function (editor) {
                        
                        const inputFile = document.createElement('input');
                        inputFile.style.display = 'none';
                        inputFile.setAttribute('type', 'file');
                        document.body.appendChild(inputFile);
                        inputFile.onchange = function (e) {
                            var fileData = e.target.files[0]
                            console.log(fileData)
                            var formdata = new FormData();
                            formdata.append("file", fileData);
                            indexApi.upLoad(formdata).then(res=>{
                                if(res.code=200){
                                    var str = '<img class="editor-img" src="' + res.url + '">'
                                    editor.execCommand("insertHTML", str)
                                }
                                document.body.removeChild(inputFile); 
                            }).catch(() => {
                                document.body.removeChild(inputFile); 
                            })
                        }
                        inputFile.click();                 
                    },
                    destroyed: function (editor) {
                        // alert("time module destroyed")
                    }
                }
            ]
        })
    }
}