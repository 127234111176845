import {
    Popup,
    NumberKeyboard,
    Icon,
    Button,
    Toast,
    Swipe,
    SwipeItem,
    Lazyload,
    RadioGroup,
    Radio,
    Checkbox,
    PullRefresh,
    List,
    Image,
    Picker,
    DropdownMenu,
    DropdownItem,
    DatetimePicker,
    Form,
    Field,
    Switch,
    Dialog,
    Calendar,
    Uploader,
    Stepper,
    Step,
    Steps,
    Loading,
    Empty,
    NoticeBar,
    CellGroup,
    Cell,
} from 'vant';

export default {
    install: (vue) => {
        vue.use(NumberKeyboard)
        vue.use(Popup)
        vue.use(Icon)
        vue.use(Button)
        vue.use(Toast)
        vue.use(Swipe)
        vue.use(SwipeItem)
        vue.use(Lazyload)
        vue.use(Radio)
        vue.use(RadioGroup)
        vue.use(Form)
        vue.use(Field)
        vue.use(Checkbox);
        vue.use(Radio);
        vue.use(RadioGroup);
        vue.use(Switch);
        vue.use(PullRefresh);
        vue.use(List);
        vue.use(Image);
        vue.use(Picker);
        vue.use(DropdownMenu);
        vue.use(DropdownItem);
        vue.use(Dialog);
        vue.use(DatetimePicker);
        vue.use(Calendar);
        vue.use(Uploader);
        vue.use(Stepper);
        vue.use(Step);
        vue.use(Steps);
        vue.use(Loading);
        vue.use(Empty)
        vue.use(NoticeBar)
        vue.use(CellGroup)
        vue.use(Cell)
    }
}