<template>
	<div>
		<div class="top-height"></div>
		<div class="nav-top" :style="{ backgroundColor: backgroundColor, color: color }">
			<div class="nav-top-left" @click="goBack">
				<!-- <img class="go-back" :src="require('@/assets/images/go-back.png')"> -->
        <van-icon class="go-back" name="arrow-left" size="18px" />
			</div>
			<div class="nav-top-center">{{ text }}</div>
			<div class="nav-top-right" @click="onMenu">{{menu}}</div>
		</div>
	</div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      default: ''
    },
    menu: {
      type: String,
      default: ''
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {}
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    onMenu() {
      this.$emit('menu')
    }
  }
}
</script>
<style lang="less" scoped>
@import "@/assets/css/base.less";
.nav-top {
  display: flex;
  height: 44px;
  background-color: @primary;
  justify-content: space-between;
	position:fixed;width:100%;z-index:99;
	top:0;
  .nav-top-center {
    text-align: center;
    flex: 1;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    // color: #fff;
    font-size: 16px;
  }
  .nav-top-left, .nav-top-right {
    width: 50px;
    height: 100%;
  }
  .nav-top-left {
    display: flex;
    align-items: center;
    padding: 16px;
  }
  .go-back {
    width: 10px;
    height: 18px;
  }
  .nav-top-right {
    color: #fff;
    font-size: 14px;
    text-align: right;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
</style>
