export default {
 	checkLogin(){
		return JSON.parse(localStorage.getItem('userInfo')) || '';
 	},
	checkDate(value,args=0){
		var dt = new Date(value);
		let year = dt.getFullYear();
		let month = dt.getMonth() + 1;
		let date = dt.getDate();
		let hour = dt.getHours();
		let minute = dt.getMinutes();
		let second = dt.getSeconds();
		if(args){
			month=month.toString().padStart(2,'0');
			date=date.toString().padStart(2,'0');
			hour=hour.toString().padStart(2,'0');
			minute=minute.toString().padStart(2,'0');
			second=second.toString().padStart(2,'0');
		}
		return {year:year,month:month,date:date,hour:hour,minute:minute,second:second};
	},
	trimStr(value,type=0){
		return type==0?(value.replace(/\s/g,'')):(value.replace(/^\s+|\s+$/g,''));
	}
}