const filter = {
    formatDate: function(value,args) {
        var dt = new Date(value);
        if(args == 'yyyy-M-d') {// yyyy-M-d
            let year = dt.getFullYear();
            let month = dt.getMonth() + 1;
            let date = dt.getDate();
            return `${year}-${month}-${date}`;
        } else if(args == 'yyyy-M-d H:m:s'){// yyyy-M-d H:m:s
            let year = dt.getFullYear();
            let month = dt.getMonth() + 1;
            let date = dt.getDate();
            let hour = dt.getHours();
            let minute = dt.getMinutes();
            let second = dt.getSeconds();
            return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
        } else if(args == 'yyyy-MM-dd') {// yyyy-MM-dd
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2,'0');
            let date = dt.getDate().toString().padStart(2,'0');
            return `${year}-${month}-${date}`;
        } else {// yyyy-MM-dd HH:mm:ss
            let year = dt.getFullYear();
            let month = (dt.getMonth() + 1).toString().padStart(2,'0');
            let date = dt.getDate().toString().padStart(2,'0');
            let hour = dt.getHours().toString().padStart(2,'0');
            let minute = dt.getMinutes().toString().padStart(2,'0');
            let second = dt.getSeconds().toString().padStart(2,'0');
            return `${year}-${month}-${date} ${hour}:${minute}:${second}`;
        }
    },
		money:function(value){
			if (value==0) return 0;
			if (!value) return '';
			value=Math.round(value*100)/100;
			var valueArray = value.toString().split('.');
			var intPartFormat = valueArray[0].toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
			var floatPart =valueArray[1]; // 预定义小数部分
			if (floatPart) { // =2表示数据有小数位
			  return intPartFormat + '.' + floatPart;
			} else {
			  return intPartFormat;
			}
		}
}
export default filter;