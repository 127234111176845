import Vue from 'vue'
import VueRouter from 'vue-router'
import { UserApi } from '@/apis/index.js'
import { Toast } from 'vant'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('../views/login/index')
  },
  {
    path: '/bind',
    component: () => import('../others/bind')
  },
  {
    path: '/workbenches',
    component: () => import('../views/workbenches'),
    children: [
      {
        path: '/reset',
        component: () => import('../views/login/reset-password')
      },
      {
        path: '/home',
        component: () => import('../views/home/index')
      },
      {
        path: '/home/ranking',
        component: () => import('../views/home/ranking/index.vue')
      },
      {
        path: '/income',
        component: () => import('../views/home/income')
      },
      {
        path: '/machine',
        component: () => import('../views/machine/index')
      },
      {
        path: '/machine/out-stock',
        component: () => import('../views/machine/out-stock')
      },
      {
        path: '/machine/out-stock-detail',
        component: () => import('../views/machine/out-stock-detail')
      },
      {
        path: '/machine/address',
        component: () => import('../views/machine/address')
      },
      {
        path: '/machine/add',
        component: () => import('../views/machine/add')
      },
      {
        path: '/machine/update',
        component: () => import('../views/machine/update')
      },
      {
        path: '/machine/detail',
        component: () => import('../views/machine/detail')
      },
      {
        path: '/machine/distribution',
        component: () => import('../views/machine/distribution')
      },
      {
        path: '/order',
        component: () => import('../views/order/index')
      },
      {
        path: '/merchant',
        component: () => import('../views/merchant/index')
      },
      {
        path: '/merchant/mer-audit',
        component: () => import('../views/merchant/mer-audit')
      },
      {
        path: '/merchant/subject',
        component: () => import('../views/merchant/subject')
      },
      {
        path: '/updateWxReal',
        component: () => import('../views/merchant/updateWxReal')
      },
      {
        path: '/updatePayReal',
        component: () => import('../views/merchant/updatePayReal')
      },
      {
        path: '/merchant/settlement',
        component: () => import('../views/merchant/settlement')
      },
      {
        path: '/merchant/manage',
        component: () => import('../views/merchant/manage')
      },
      {
        path: '/merchant/preview',
        component: () => import('../views/merchant/preview')
      },
      {
        path: '/merchant/realname',
        component: () => import('../views/merchant/realname')
      },
      {
        path: '/merchantup',
        component: () => import('../views/merchantUpdate/index')
      },
      {
        path: '/merchantup/subject',
        component: () => import('../views/merchantUpdate/subject')
      },
      {
        path: '/merchantup/settlement',
        component: () => import('../views/merchantUpdate/settlement')
      },
      {
        path: '/merchantup/manage',
        component: () => import('../views/merchantUpdate/manage')
      },
      {
        path: '/merchantup/preview',
        component: () => import('../views/merchantUpdate/preview')
      },
      {
        path: '/replenishment',
        component: () => import('../views/replenishment/index')
      },
      {
        path: '/user',
        component: () => import('../views/user/index')
      },
      {
        path: '/user/index',
        component: () => import('../views/user/index.vue')
      },
      {
        path: '/user/notice',
        component: () => import('../views/user/notice.vue')
      },
      // {
      //   path: '/user/customer',
      //   component: () => import('../views/user/customer.vue')
      // },
      {
        path: '/user/update-password',
        component: () => import('../views/user/update-password.vue')
      },
      {
        path: '/user/material',
        component: () => import('../views/user/material.vue')
      },
      {
        path: '/user/renew',
        component: () => import('../views/user/renew.vue')
      },
      {
        path: '/user/renewal-support',
        component: () => import('../views/user/renewal-support')
      },
      // {
      //   path: '/user/recharge',
      //   component: () => import('../views/user/recharge')
      // },
      {
        path: '/order/detail',
        component: () => import('../views/order/detail')
      },
      {
        path: '/order/refund',
        component: () => import('../views/order/refund-detail')
      },
      {
        path: '/order/third',
        component: () => import('../views/order/third-party')
      },
      {
        path: '/replenishment/replenishment',
        component: () => import('../views/replenishment/replenishment')
      },
      {
        path: '/product/index',
        component: () => import('../views/product/index')
      },
      {
        path: '/product/update',
        component: () => import('../views/product/update')
      },
      {
        path: '/product/warehouse',
        component: () => import('../views/product/warehouse')
      },
      {
        path: '/product/add',
        component: () => import('../views/product/add')
      },
      {
        path: '/product/classification',
        component: () => import('../views/product/classification')
      },
      {
        path: '/machine/track-set',
        component: () => import('../views/machine/freight-track-set')
      },
      {
        path: '/product/shipping-record',
        component: () => import('../views/product/shipping-record')
      },
      {
        path: '/statistics/operations',
        component: () => import('../views/statistics/operations.vue')
      },
      {
        path: '/bill',
        component: () => import('../views/user/bill.vue')
      },
      {
        path: '/bill-detail',
        component: () => import('../views/user/bill-detail.vue')
      }
    ]
  }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})
/*
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
  } else {
    let token = localStorage.getItem('token')
    if (token) {
        let checkedKeys = localStorage.getItem('checkedKeys')
        if (checkedKeys) {
          next()
        } else {
          if (+localStorage.getItem('userId') === 1) {
            let sysMenuVosData = localStorage.getItem('sysMenuVosData')
            if (sysMenuVosData) {
              let sysMenuVos = JSON.parse(sysMenuVosData)
              let menuId = sysMenuVos.map((ev) => {
                return ev.menuId
              })
              localStorage.setItem('checkedKeys', JSON.stringify(menuId))
            } else {

            }
            next()
          } else {
            let params = {
              id: localStorage.getItem('userId')
            }
            UserApi.userMenuTreeselect(params).then((res) => {
              if (res.code === 200) {
                let checkedKeys = res.checkedKeys
                console.log(checkedKeys)
                localStorage.setItem('checkedKeys', JSON.stringify(checkedKeys))
                router.app.$options.store.state.sysMenuVos = checkedKeys
                console.log(router.app.$options.store.state)
                next()
              } else {
                Toast('获取权限失败')
              }
            }).catch(() => {
              Toast('获取权限失败')
            })
          }
      }
    } else {
      next('/login')
    }
  }
})
*/

export default router
