import { instance, instanceNoToken } from './ajax.js'

//用户
export const UserApi = {
    login(params) {
        return instanceNoToken.post('/api/userApi/getLogin',params)
    },
    // 续费设备
    getFacilityList( params ) {
        return instance.get('/api/facility/getFacilityList',{ params } )
    },
    // 用户信息
    getUserInfo(params) {
        return instance.post('/api/userApi/getUserInfo?userId=' + params.userId )
    },
    // 当前用户信息
    getCurrentUserInfo(params) {
        return instance.post('/api/userApi/getCurrentUserInfo?userId=' + params.userId )
    },

    //登录用户信息
    getLoginUserInfo(params) {
        return instance.post('/api/userApi/getLoginUserInfo?userId=' + params.userId )
    },
    // 修改客服电话
    updateTel(params) {
        return instance.put('/api/oper/updateTel', { params } )
    },
    // 获取权限
    userMenuTreeselect(params) {
        return instance.get('/api/userApi/userMenuTreeselect/' + params.id)
    },
    // 通知开关
    updateStatus(params) {
        return instance.post('/api/oper/updateStatus?operatorId=' + localStorage.getItem('operatorId'), params)
    },
    getStatus(params) {
        return instance.post('/api/oper/getOpenStatus?operatorId=' + localStorage.getItem('operatorId'), params)
    },
    updateOpenId(params) {
        return instance.post('/api/oper/updateOpenId', params)
    },
    // 
    forgetPwdGetCode(params) {
        return instance.post('/api/userApi/forgetPwdGetCode', params)
    },
    // 
    forgetPwd(params) {
        return instance.post('/api/userApi/forgetPwd', params)
    },
    
    getYue(params) {
        return instance.get('/api/oper/getYue', { params })
    },
    updatePwd(params) {
        return instance.post('/api/userApi/updatePwd', params)
    }

}
// 首页
export const HomeApi = {
    selectInComeInfo() {
        return instance.get('/api/goodsOrder/selectInComeInfo')
    },
    selectFacilityInfo() {
        return instance.get('/api/goodsOrder/selectFacilityInfo')
    },
    //统计汇总
    selectHotSaleH5Total(params, config) {
        return instance.get('/api/goodsOrder/selectHotSaleH5Total', { params, headers: {
            ...config
        } })
    },
    //热销商品
    selectHotSaleGoodsH5(params, config) {
        return instance.get('/api/goodsOrder/selectHotSaleGoodsH5', { params, headers: {
            ...config
        } })
    },
    //热销商品明细
    selectHotSaleGoodsH5Detail(params, config) {
        return instance.get('/api/goodsOrder/selectHotSaleGoodsH5Detail', { params, headers: {
            ...config
        } })
    },
    //热销设备
    selectHotSaleFacilityH5(params, config) {
        return instance.get('/api/goodsOrder/selectHotSaleFacilityH5', { params, headers: {
            ...config
        } })
    },
    //热销设备明细
    selectHotSaleFacilityH5Detail(params, config) {
        return instance.get('/api/goodsOrder/selectHotSaleFacilityH5Detail', { params, headers: {
            ...config
        } })
    },
    //近七日收入
    selectWeekInComeH5() {
        return instance.get('/api/goodsOrder/selectWeekInComeH5')
    },
    monthIncome(params) {
        return instance.get('/api/goodsOrder/monthIncome', { params } )
    },
    // 
    expriceFacility(params) {
        return instance.get('/api/facility/expriceFacility', { params } )
    }
}
//商品
export const GoodsApi = {
        selectGoodsByOp(params, pam) {
            return instance.get('/api/track/selectGoodsByOp/'+ params.type, { params: pam })
        },
        goodsList(params) {
          return instance.get('/api/goodsOperator/getIGoodsOperatorList', { params })
        },
		goodsInfo(params){
			return instance.get('/api/goodsOperator/getGoodsInfo/'+params.id)

		},
        getGoodsOperatorInfo(params) {
            return instance.get('/api/goodsOperator/getGoodsOperatorInfo/'+params.id)
        },
		//修改商品
		goodsUpdate(params){
			return instance.post('/api/goodsOperator/putGoodsOperator',params)
		},
        goodsUpdateByAdmin(params){
			return instance.post('/api/goodsOperator/updategoodsInfo',params)
		},
		goodsInsert(params){
			return instance.post('/api/goodsOperator/selfBuiltGoods',params)
		},
		//删除商品
		goodsDel(params){
			return instance.get('/api/goodsOperator/deleteIGoodsOperator/'+params.id)
		},
        goodsDelByAdmin(params){
			return instance.get('/api/goodsOperator/deleteIGoodsInfo/'+params.id)
		},
		//批量库删除
		delAll(params){
			return instance.get('/api/goodsOperator/'+params)
		},
		cateAll(){
			return instance.get('/api/goodsType/allType/')
		},
		cateData(params){
			return instance.post('/api/goodsType/getIGoodsTypeList',params)
		},
		addCate(params){
			return instance.post('/api/goodsType/insertOrdertype',params)
		},
		delCate(params){
			return instance.get('/api/goodsType/deleteOrdertype/',{params})
		},
		allOperator(){
			return instance.get('/api/oper/allOperator/')
		},
		goodStock(){
			return instance.get('/api/track/selectGoodsByOp/0')
		}
}


let authorization = 'Bearer eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImFmNGMwMzBmLWRkNTAtNDkyNC1iOGU2LWIzMmFmYjM0OWMxMiJ9.Y1fay66_usS6NDGz-qrMmLhRQkJstgGsC8Bpz7zFPNrrDKrbsMPYUTe3QzYw7qNjjTziuQbVrmZEHJFW7uiH4Q'

//设备
export const FacilityApi = {
	// 机器列表
	facility( params ){
		return instance.get('/api/facility/getFacilityList', {  params })
	},
    // 缺货设备
    getLackFacilityList(params) {
        return instance.get('/api/facility/getLackFacilityList', {  params })
    },
    getLackFacilityInfo(params) {
        return instance.get('/api/facility/getLackFacilityInfo/' + params.id)
    },
	// 新增
    insertFacility (data) {
        return instance.post('/api/facility/insertFacility', data)
    },
    // 设备详情
    selectFacilityInfo (params) {
        return instance.get('/api/facility/selectFacilityInfo/' + params.id)
    },
    // 修改设备信息
    updateFacility (params) {
        return instance.put('/api/facility/updateFacility', params)
    },
    // 查询配货列表
    selectShareList (params) {
        return instance.get('/api/facility/selectShareList/' + params.id)
    },
    // 货道列表
    selectTrackByFaId (params) {
        return instance.get('/api/track/selectTrackByFaId/' + params.id)
    },
    //
    updateBatch(params) {
        return instance.post('/api/track/updateBatch',  params)
    },
    updateDisStatus(params) {
	    return instance.post('/api/facility/updateDisStatus', params)
    },
    // 批量货道容量
    updateCountList(params) {
        return instance.post('/api/track/updateCountList',  params)
    },
    // 批量修改货道状态
    updateStartStatusList(params) {
        return instance.post('/api/track/updateStartStatusList',  params)
    },
    // 一件测试
    trackListTest(params) {
        return instance.put('/api/track/trackListTest/' + params.id)
    },
    // 货道测试 '/api/track/testAllTrack/'
    testAllTrack(url, params) {
        return instance.put(url + params.id)
    },
    trackTestsDemo(params) {
        return instance.put('/api/track/trackTestsDemo/' + params.id)
    },
    // 货道修改
    track(params) {
        return instance.put('/api/track', params)
    },
    //  修改货道商品
    updateTrackGoods(params) {
        return instance.post('/api/track/addMultiple', params)
    },
    // 补货
    insetGoods(params) {
        return instance.post('/api/track/insetGoods', params)
    },
    insertAll(params) {
        return instance.post('/api/track/insertAll/' + params.id)
    },
    // 出货 localhost:9001/api/facility/recordList?facilityId=237&pageNum=1&pageSize=10
    recordList(params) {
	    return instance.get('/api/facility/recordList', { params })
    },
    // 优惠
    updateDiscount(params) {
        return instance.put('/api/track/updateDiscount', params)
    },
    // localhost:9001/vending/facility/fac/335
    fac(params) {
        return instance.get('/api/facility/selectFacilityInfo/' + params.id, { headers: { Authorization: authorization} })
    },
    // localhost:9001/vending/facility/LocationUpdate
    locationUpdate(params) {
        return instance.put('/api/facility/updateLocation', params, { headers: { Authorization: authorization} })
    },
    //一键开柜
    openCabinet(params) {
        return instance.post('/api/track/openCabinet/'+ params.id)
    },
    //一键开柜货道编号
    openCabinetTrack(params) {
        return instance.post('/api/track/openCabinetTrack/'+ params.id)
    },
    // 设置预警值
    setWarning(params) {
        return instance.post('/api/facility/warningSettings/', params)
    }
}

export const ApiOper = {
    entIncoming(params) {
        return instance.post('/api/oper/entIncoming', params)
    },
    operUpdate(params) {
        return instance.post('/api/oper/update', params)
    },
    upload(params) {
        return instance.post('/api/fileUpload/uploadHuifu?type=1&operatorId=' + localStorage.getItem('operatorId'), params)
    },
    getWxRealStatus(params) {
        return instance.post('/api/oper/getWxRealStatus?operatorId=' + localStorage.getItem('operatorId'), params)
    },
    getAliRealStatus(params) {
        return instance.post('/api/oper/getAliRealStatus?operatorId=' + localStorage.getItem('operatorId'), params)
    },
    getOperatorInfo(params) {
        return instance.post('/api/oper/getOperatorInfo?operatorId=' + localStorage.getItem('operatorId'), params)
    },
    renewPrice(params) {
        return instance.post('/api/facility/renewPrice?operatorId=' + localStorage.getItem('operatorId'), params)
    },
    // 创建续费订单
    createOrder(params) {
        return instance.post('/api/facility_order/createOrder', params )
    },
    // 续费订单拉起支付
    pullPayFacility(data) {
        return instance.post('/api/pay/pullPayFacility', data)
    },
    // 运营商续费
    createOrderbByRecharge(data) {
        return instance.post('/api/recharge_log/createOrder', data)
    },
    // 运营商续费订单拉取支付
    pullPayRecharge(data) {
        return instance.post('/api/pay/pullPayRecharge', data)
    },
    // 
    // localhost:9001/api/oper/realName?operatorId=&cardFront=&cardBack=&shopHead=&shopInside=
    realName(params) {
        return instance.post('/api/oper/realName', params)
    },
    getTimeRange(params) {
        return instance.get('/api/oper/getTimeRange', { params })
    }
}
export const PayApi = {
    pullPay(data) {
        return instance.post('/api/pay/pullFacilityPay', data)
    },
    getApiGetopenid(params) {
        return instance.get('/api/getoOenid/getApiGetopenid', { params })
    }
}
//公用的
export const CommonApi = {
    // api/dbType/allType
    allType() {
        return instance.get('/api/dbType/allType')
    },
    // /api/oper/allOperator
    allOperator() {
        return instance.get('/api/oper/allOperator')
    },
    updateOpenId(params) {
        return instance.post('/api/oper/updateOpenId', params)
    },
    delOpenId(params) {
        return instance.post('/api/oper/delOpenId', params)
    },
    getFacility() {
        return instance.get('/api/facility/getFacility')
    },
    getFacilityArea() {
        return instance.get('/api/facility/getFacilityArea')
    },
    getGoodsInfo() {
        return instance.get('/api/goodsOperator/getGoodsInfo')
    },
    getGoodsType() {
        return instance.get('/api/goodsType/allType') 
    }
}

export const OrderApi = {
    // 订单列表
    list(params, config) {
        return instance.get('/api/goodsOrder/list', { params, headers: {
            ...config
        } })
    },
    // 订单详情
    goodsOrderInfo(params) {
        return instance.get('/api/goodsOrder/goodsOrderInfo/' + params.id)
    },
    // 退款
    getMoneyByCount(params) {
        return instance.post('/api/goodsOrder/getMoneyByCount', { params })
    },
    // 退款回显
    getOrderInfo(params) {
        return instance.get('/api/goodsOrder/getOrderInfo/' + params.id)
    },
    // 确认退款
    refundMoney(params) {
        return instance.put('/api/goodsOrder/refundMoney',  params)
    },
    // 全额退款
    refundAll(params) {
        return instance.put('/api/goodsOrder/refundAll/' + params.id )
    },
    topParam(params) {
        return instance.get('/api/goodsOrder/topParam', { params })
    }
}

//首页
export const indexApi={
	//首页统计
	indexInfo(){
		return instance.get('api/goodsOrder/selectInComeInfo')
	},
	upLoad(params){
		return instance.post('api/fileUpload/upload',params)
	}
}

export const StaticApi = {
    operatorOrders(params, config) {
        return instance.get('/api/goodsOrder/operatorOrders', { params, headers: {
            ...config
        } })
    },
    facilityOrders(params, config) {
        return instance.get('/api/goodsOrder/facilityOrders', { params, headers: {
            ...config
        } })
    }
}

export const ApiBill = {
    getAccountLog(params) {
        return instance.get('/api/oper/getAccountLog', { params })
    },
    getAccountLogInfo(params) {
        return instance.get('/api/oper/getAccountLogInfo', { params })
    }
}
