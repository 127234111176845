<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import {PayApi, UserApi} from "@/apis";
    export default {
        data() {
            return {
                showOffLine: false,
                querySearch: {}
            }
        },
        computed: {
            ...mapState({
                code: 'code',
                alipayCode: 'alipayCode'
            })
        },
        created() {
					let userInfo=JSON.parse(localStorage.getItem('userInfo')) || '';
					this.setUserInfo(userInfo);
        },
        mounted() {

        },
        methods: {
              ...mapMutations({
                  setIsCanPayByWeixin: 'setIsCanPayByWeixin',
                  setCode: 'setCode',
                  setOpenId: 'setOpenId',
                  setAlipayCode: 'setAlipayCode',
                  setUserInfo:'setUserInfo',
                  setSysMenuVos: 'setSysMenuVos',
              })
          }
    }
</script>

<style lang="less" scoped>
    .btn-success {
        width: 196px;
        height: 42px;
        background: linear-gradient(270deg, #98C3C4 0%, #D1EBE6 100%);
        box-shadow: 0px 5px 10px 1px rgba(75,122,123,0.1);
        border-radius: 100px 100px 100px 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 16px;
        margin: auto;
    }
</style>
