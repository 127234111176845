import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    shoppingCart: [],
    machineInfo: { // 售货机信息
      facilityName: '',
      facilityNumber: '',
      facilityTel: ''
    },
    isCanPayByWeixin: false,
    code: '',
    openId: '',
    alipayCode: '',
    userInfo:'',
    sysMenuVos: [],
    showMerAudit: false,
    showRenew: true
  },
  getters: {
    shoppingCartNum(state) {
      let obj = {}
      state.shoppingCart.forEach((ev) => {
        obj[ev.id] = ev.num
      })
      return obj
    },
    goodsPriceTotal(state) {
      let numPrice = 0
      state.shoppingCart.forEach((ev) => {
        numPrice = ev.num * ev.price + numPrice
      })
      return numPrice.toFixed(2)
    },
    buyGoodsNum(state) {
      let num = 0
      state.shoppingCart.forEach((ev) => {
        num = num + ev.num
      })
      return num
    }
  },
  mutations: {
    setShowMerAudit(state, v) {
      state.showMerAudit = v
    },
    setShowRenew(state, v) {
      state.showRenew = v
    },
    setSysMenuVos(state, v) {
      state.sysMenuVos = v
    },
    setShoppingCart(state, obj) {
      let mapId = state.shoppingCart.map((ev) => {
        return ev.id
      })
      if (mapId.includes(obj.id)) {
        state.shoppingCart.forEach((ev) => {
          if (ev.id === obj.id) {
            if (ev.num < ev.stock) {
              ev.num++
            }
          }
        })
      } else {
        obj.num = 1
        state.shoppingCart.push(obj)
      }
    },
    reduceGoods(state, id) {
      state.shoppingCart.forEach((ev, i) => {
        if (ev.id === id) {
          ev.num--
          if (ev.num === 0) {
            state.shoppingCart.splice(i, 1)
          }
        }
      })
    },
    addGoods(state, id) {
      state.shoppingCart.forEach((ev, i) => {
        if (ev.id === id) {
          if (ev.num < ev.stock) {
            ev.num++
          }
        }
      })
    },
    clearShoppingCart(state) {
      state.shoppingCart = []
    },
    setMachineInfo(state, v) {
      state.machineInfo = v
    },
    setIsCanPayByWeixin(state, v) {
      state.isCanPayByWeixin = v
    },
    setCode(state, v) {
      state.code = v
    },
    setOpenId(state, v) {
      state.openId = v
    },
    setAlipayCode(state, v) {
      state.alipayCode = v
    },
    setUserToken(state,v){
        state.userToken=v
    },
    setUserInfo(state,v){
        state.userInfo=v
    }
  },
  actions: {
  },
  modules: {
  }
})
